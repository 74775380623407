import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import { media } from './common';
import { colorsBar, colors } from '../../../ui/common';

const Button = styled.button`
  background: transparent;
  border: none;
  padding: 0 4px;
  outline: none;
  cursor: pointer;
  svg {
    transition: transform 0.25s ease-in-out;
    transform: ${(props) => (props.active ? 'none' : 'rotate(45deg)')};
    display: block;
    fill: ${(props) => (props.chillBlue ? colorsBar.blue : colors.color09)};
  }
  ${media.tablet`
    padding: 0 8px;
  `}
`;

function Toggle({ onClick, active, chillBlue }) {
  return (
    <Button
      aria-label={active ? 'Hide info' : 'Show info'}
      chillBlue={chillBlue}
      type="button"
      onClick={onClick}
      active={active}
    >
      <PlusIcon />
    </Button>
  );
}
export default Toggle;

export function PlusIcon() {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29 29"
      width="29"
      hanging="29"
    >
      <path d="M.799 14.799c0-7.732 6.268-14 14-14s14 6.268 14 14-6.268 14-14 14-14-6.268-14-14z" />
      <path
        d="M11.26 17.703a.5.5 0 10.696.717l2.669-2.594 2.69 2.69a.5.5 0 00.707-.707l-2.68-2.68 3.068-2.982a.5.5 0 00-.697-.717l-3.078 2.992-2.977-2.977a.5.5 0 10-.707.707l2.967 2.967-2.659 2.584z"
        fill="#fff"
      />
    </svg>
  );
}

Toggle.propTypes = {
  onClick: PropType.func,
  active: PropType.bool,
  chillBlue: PropType.bool,
};

Toggle.defaultProps = {
  onClick: null,
  active: false,
  chillBlue: false,
};
