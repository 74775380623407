import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import { colors } from '../../../ui/common';

const maxWidthCss = css`
  max-width: 1440px;
  margin: 0 auto;
`;
export const MaxWidthWrapper = styled.div`
  ${maxWidthCss};
`;
export const HeroOuterWrapper = styled.div`
  background: #f7fbfb;
`;
export const HeroInnerWrapper = styled.div`
  position: relative;
  height: 712px;
`;
export const CropableGatsbyImage = styled(GatsbyImage)`
  height: 100%;
`;
export const HeroContentWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12%;
  right: 12px;
  max-width: 632px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;

  @media (max-width: 768px) {
    left: 12px;
  }
`;
export const H1 = styled.h1`
  font-weight: 500;
  font-size: 52px;
  line-height: 122.9%;
  margin-bottom: 20px;
`;
export const HeroText = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 170%;
  margin-bottom: 40px;
`;
export const MobileAppsStoreList = styled.div`
  display: flex;
`;
export const MobileAppsStoreLink = styled.a`
  width: 180px;
  height: 60px;
  background: ${colors.color06};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  transition: transform 300ms ease-in-out, background 300ms ease-in-out;

  &:not(:last-of-type) {
    margin-right: 30px;
  }

  &:hover {
    background: #02817d;
  }

  &:focus {
    background: #03a39e;
  }

  &:active {
    transform: scale(1.05);
  }
`;
export const MobileAppsStoreImage = styled.img`
  margin-right: 10px;
`;

export const RemoteControlWrapper = styled.section`
  background: #f7fbfb;
  padding-bottom: 64px;
`;
export const RemoteControlHeader = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 150%;
  text-align: center;
  max-width: 596px;
  padding: 86px 0 80px;
  margin: 0 auto;
  color: ${colors.color05};
`;
export const RemoteControlList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
export const RemoteControlItem = styled.div`
  margin: 26px 32px;
  flex: 0 0 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: ${colors.color06};
`;
export const RemoteControlIconWrapper = styled.div`
  height: 62px;

  > img {
    height: 100%;
  }
`;

export const StatsAndConditionWrapper = styled.section`
  display: flex;
  padding: 100px 132px 100px 72px;
  ${maxWidthCss};

  @media (max-width: 1024px) {
    display: block;
    padding: 100px 44px 100px 44px;
  }
`;
export const StatsAndConditionHero = styled.div`
  flex: 0 0 550px;
  max-width: 550px;
  margin: 0 auto;
`;
export const StatsAndConditionContent = styled.div`
  flex: 1;
`;
export const StatsAndConditionHeader = styled.h2`
  margin-left: 32px;
  font-weight: 600;
  font-size: 38px;
  line-height: 150%;
  color: ${colors.color05};

  @media (max-width: 1024px) {
    text-align: center;
    margin-left: 0;
  }
`;
export const StatsAndConditionList = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;
export const StatsAndConditionItem = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 32px;
`;
export const StatsAndConditionItemText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${colors.color01};
  width: 154px;
`;
export const StatsAndConditionIconWrapper = styled.div`
  box-sizing: border-box;
  width: 95px;
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eff3f8;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: -9px 9px 16px rgb(163 177 198 / 60%),
    -9px -9px 16px rgb(255 255 255 / 60%);
  border-radius: 20px;
  margin-right: 30px;
`;
