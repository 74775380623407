import { useEffect } from 'react';
import getMobileOperatingSystem from '../utils/get-mobile-operating-system';

const appleStoreUrl = 'https://apps.apple.com/app/evapolar/id1275050081';
const googlePlayUrl =
  'https://play.google.com/store/apps/details?id=com.evapolar.eva';

const useApps = () => {
  useEffect(() => {
    if (getMobileOperatingSystem() === 'iOS') {
      window.location = appleStoreUrl;
    } else if (getMobileOperatingSystem() === 'Android') {
      window.location = googlePlayUrl;
    }
  }, []);

  return { appleStoreUrl, googlePlayUrl };
};

export default useApps;
