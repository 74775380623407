import React from 'react';
import { useIntl } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import appleStoreIcon from '../../../images/apps/apple-store.svg';
import googlePlayIcon from '../../../images/apps/google-play.svg';

import airflowControlIcon from '../../../images/apps/remote-control/airflow-control.svg';
import firmwareUpdateIcon from '../../../images/apps/remote-control/firmware-update.svg';
import ledLightingControlAndPlaylistTuningIcon from '../../../images/apps/remote-control/led-lighting-control-and-playlist-tuning.svg';
import nightModeIcon from '../../../images/apps/remote-control/night-mode.svg';
import onOffSwitchIcon from '../../../images/apps/remote-control/on-off-switch.svg';
import scheduledCoolingIcon from '../../../images/apps/remote-control/scheduled-cooling.svg';

import cartridgeLifespanIndicatorIcon from '../../../images/apps/stats-and-condition/cartridge-lifespan-indicator.svg';
import contributionToWildlifePreservationIcon from '../../../images/apps/stats-and-condition/contribution-to-wildlife-preservation.svg';
import deviceUsageStatsIcon from '../../../images/apps/stats-and-condition/device-usage-stats.svg';
import statsOnEnergyEfficiencyIcon from '../../../images/apps/stats-and-condition/stats-on-energy-efficiency.svg';
import waterAmountInTheWaterTankIcon from '../../../images/apps/stats-and-condition/water-amount-in-the-water-tank.svg';

import Navbar from '../../Navbar';
import Footer from '../../Footer';
import FAQ from '../../FAQ';

import useApps from '../../../hooks/use-apps';

import {
  MaxWidthWrapper,
  HeroOuterWrapper,
  HeroInnerWrapper,
  CropableGatsbyImage,
  HeroContentWrapper,
  H1,
  HeroText,
  MobileAppsStoreList,
  MobileAppsStoreLink,
  MobileAppsStoreImage,
  RemoteControlWrapper,
  RemoteControlHeader,
  RemoteControlList,
  RemoteControlItem,
  RemoteControlIconWrapper,
  StatsAndConditionWrapper,
  StatsAndConditionHero,
  StatsAndConditionContent,
  StatsAndConditionHeader,
  StatsAndConditionList,
  StatsAndConditionItem,
  StatsAndConditionItemText,
  StatsAndConditionIconWrapper,
} from './styled';

function Apps() {
  const intl = useIntl();
  const { appleStoreUrl, googlePlayUrl } = useApps();
  const imagesData = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "apps/hero.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 95, layout: FULL_WIDTH)
        }
      }
      appCapabilities: file(relativePath: { eq: "apps/app-capabilities.png" }) {
        childImageSharp {
          gatsbyImageData(width: 586, quality: 85, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <>
      <Navbar />
      <HeroOuterWrapper>
        <MaxWidthWrapper>
          <HeroInnerWrapper>
            <CropableGatsbyImage
              image={imagesData.hero.childImageSharp.gatsbyImageData}
              alt={intl.formatMessage({ id: 'apps.hero.image.alt' })}
            />
            <HeroContentWrapper>
              <H1>{intl.formatMessage({ id: 'apps.header' })}</H1>
              <HeroText>
                {intl.formatMessage({ id: 'apps.hero.text' })}
              </HeroText>
              <MobileAppsStoreList>
                {[
                  [
                    'apps.apple.store',
                    'apps.apple.store.icon.alt',
                    appleStoreUrl,
                    appleStoreIcon,
                  ],
                  [
                    'apps.google.play',
                    'apps.google.play.icon.alt',
                    googlePlayUrl,
                    googlePlayIcon,
                  ],
                ].map(([textId, iconAltId, url, icon]) => (
                  <MobileAppsStoreLink href={url} key={textId}>
                    <MobileAppsStoreImage
                      src={icon}
                      alt={intl.formatMessage({ id: iconAltId })}
                    />
                    {intl.formatMessage({ id: textId })}
                  </MobileAppsStoreLink>
                ))}
              </MobileAppsStoreList>
            </HeroContentWrapper>
          </HeroInnerWrapper>
        </MaxWidthWrapper>
      </HeroOuterWrapper>

      <RemoteControlWrapper>
        <MaxWidthWrapper>
          <RemoteControlHeader>
            {intl.formatMessage({ id: 'apps.remote.control.header' })}
          </RemoteControlHeader>

          <RemoteControlList>
            {[
              [
                'apps.remote.control.item.1.text',
                'apps.remote.control.item.1.icon.alt',
                onOffSwitchIcon,
              ],
              [
                'apps.remote.control.item.2.text',
                'apps.remote.control.item.2.icon.alt',
                airflowControlIcon,
              ],
              [
                'apps.remote.control.item.3.text',
                'apps.remote.control.item.3.icon.alt',
                ledLightingControlAndPlaylistTuningIcon,
              ],
              [
                'apps.remote.control.item.4.text',
                'apps.remote.control.item.4.icon.alt',
                nightModeIcon,
              ],
              [
                'apps.remote.control.item.5.text',
                'apps.remote.control.item.5.icon.alt',
                scheduledCoolingIcon,
              ],
              [
                'apps.remote.control.item.6.text',
                'apps.remote.control.item.6.icon.alt',
                firmwareUpdateIcon,
              ],
            ].map(([textId, iconAltId, icon]) => (
              <RemoteControlItem key={textId}>
                <RemoteControlIconWrapper>
                  <img
                    src={icon}
                    alt={intl.formatMessage({
                      id: iconAltId,
                    })}
                  />
                </RemoteControlIconWrapper>
                <p>{intl.formatMessage({ id: textId })}</p>
              </RemoteControlItem>
            ))}
          </RemoteControlList>
        </MaxWidthWrapper>
      </RemoteControlWrapper>

      <StatsAndConditionWrapper>
        <StatsAndConditionHero>
          <GatsbyImage
            image={imagesData.appCapabilities.childImageSharp.gatsbyImageData}
            alt={intl.formatMessage({
              id: 'stats.and.condition.hero.image.alt',
            })}
          />
        </StatsAndConditionHero>
        <StatsAndConditionContent>
          <StatsAndConditionHeader>
            {intl.formatMessage({ id: 'stats.and.condition.header' })}
          </StatsAndConditionHeader>
          <StatsAndConditionList>
            {[
              [
                'stats.and.condition.item.1.text',
                'stats.and.condition.item.1.icon.alt',
                cartridgeLifespanIndicatorIcon,
              ],
              [
                'stats.and.condition.item.2.text',
                'stats.and.condition.item.2.icon.alt',
                deviceUsageStatsIcon,
              ],
              [
                'stats.and.condition.item.3.text',
                'stats.and.condition.item.3.icon.alt',
                waterAmountInTheWaterTankIcon,
              ],
              [
                'stats.and.condition.item.4.text',
                'stats.and.condition.item.4.icon.alt',
                statsOnEnergyEfficiencyIcon,
              ],
              [
                'stats.and.condition.item.5.text',
                'stats.and.condition.item.5.icon.alt',
                contributionToWildlifePreservationIcon,
              ],
            ].map(([textId, iconAltId, icon]) => (
              <StatsAndConditionItem key={textId}>
                <StatsAndConditionIconWrapper>
                  <img
                    src={icon}
                    alt={intl.formatMessage({
                      id: iconAltId,
                    })}
                  />
                </StatsAndConditionIconWrapper>
                <StatsAndConditionItemText>
                  {intl.formatMessage({ id: textId })}
                </StatsAndConditionItemText>
              </StatsAndConditionItem>
            ))}
          </StatsAndConditionList>
        </StatsAndConditionContent>
      </StatsAndConditionWrapper>

      <FAQ
        questions={[
          {
            title: 'products.faq.1.title',
            content: [
              { id: 'products.faq.1.text.1' },
              { id: 'products.faq.1.text.2' },
            ],
          },
          {
            title: 'products.faq.2.title',
            content: [
              {
                id: 'products.faq.2.text.1',
                values: {
                  // eslint-disable-next-line react/no-unstable-nested-components
                  a: (chunks) => (
                    <a
                      className="typical-inline-link"
                      href="https://support.evapolar.com/hc/en-us/articles/360019002959-How-do-evaporative-coolers-work-"
                    >
                      {chunks}
                    </a>
                  ),
                },
              },
              { img: true },
              {
                id: 'products.faq.2.text.2',
                values: {
                  // eslint-disable-next-line react/no-unstable-nested-components
                  a: (chunks) => (
                    <a
                      className="typical-inline-link"
                      href="https://support.evapolar.com/hc/en-us/articles/360019002959-How-do-evaporative-coolers-work-"
                    >
                      {chunks}
                    </a>
                  ),
                },
              },
            ],
            img: '',
          },
          {
            title: 'products.faq.3.title',
            content: [{ id: 'products.faq.3.text.1' }],
          },
          {
            title: 'products.faq.4.title',
            content: [
              {
                id: 'products.faq.4.text.1',
                values: {
                  // eslint-disable-next-line react/no-unstable-nested-components
                  a: (chunks) => (
                    <a
                      className="typical-inline-link"
                      href="https://support.evapolar.com/hc/en-us/articles/360017212840-Evapolar-optimal-working-conditions-"
                    >
                      {chunks}
                    </a>
                  ),
                },
              },
              {
                id: 'products.faq.4.text.2',
                values: {
                  // eslint-disable-next-line react/no-unstable-nested-components
                  a: (chunks) => (
                    <a
                      className="typical-inline-link"
                      href="https://support.evapolar.com/hc/en-us/articles/360017212840-Evapolar-optimal-working-conditions-"
                    >
                      {chunks}
                    </a>
                  ),
                },
              },
            ],
          },
          {
            title: 'products.faq.5.title',
            content: [{ id: 'products.faq.5.text.1' }],
          },
        ]}
      />
      <Footer />
    </>
  );
}

export default Apps;
